import React from 'react'

import PropTypes from 'prop-types'

import './blog-post-card1.css'

const BlogPostCard3 = (props) => {
  return (
    <div className={`blog-post-card1-blog-post-card ${props.rootClassName} `}>
        <img
          alt={props.imageAlt}
          src={props.imageSrc}
          className="blog-post-card1-image"
        />
      <a target="_blank" href="https://www.bka.de/DE/UnsereAufgaben/Deliktsbereiche/Cybercrime/cybercrime_node.html">
        <div className="blog-post-card1-container">
          <span className="blog-post-card1-text">{props.label}</span>
          <span className="blog-post-card1-text1">{props.title}</span>
          <span className="blog-post-card1-text2">{props.description}</span>    
        </div>
      </a>
    </div>
  )
}

BlogPostCard3.defaultProps = {
  rootClassName: '',
  label: 'Information',
  title: 'Was ist Cybercrime?',
  imageSrc:
    'https://images.unsplash.com/photo-1547841243-eacb14453cd9?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDIyfHxjaXR5fGVufDB8fHx8MTYyNjE4NjYxMg&ixlib=rb-1.2.1&w=1000',
  profileSrc:
    'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDd8fG1hbiUyMHBvcnRyYWl0fGVufDB8fHx8MTYyNjQzMTMwMw&ixlib=rb-1.2.1&h=1200',
  imageAlt: 'Was ist Cybercrime?',
  description:
    'Cybercrime ist eines der sich am dynamischsten verändernden Kriminalitätsphänomene. Täter passen sich flexibel an technische und gesellschaftliche Entwicklungen an, agieren global und greifen dort an, wo es sich aus ihrer Sicht finanziell lohnt.',
}

BlogPostCard3.propTypes = {
  rootClassName: PropTypes.string,
  label: PropTypes.string,
  author: PropTypes.string,
  title: PropTypes.string,
  imageSrc: PropTypes.string,
  profileSrc: PropTypes.string,
  imageAlt: PropTypes.string,
  profileAlt: PropTypes.string,
  description: PropTypes.string,
  time: PropTypes.string,
}

export default BlogPostCard3
