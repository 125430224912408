import React from 'react'

import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import './blog-post-card1.css'

const BlogPostCard1 = (props) => {
  return (
    <div className={`blog-post-card1-blog-post-card ${props.rootClassName} `}>
        <img
          alt={props.imageAlt}
          src={props.imageSrc}
          className="blog-post-card1-image"
        />
      <a target="_blank" href="https://www.enisa.europa.eu/publications/enisa-threat-landscape-2023">
        <div className="blog-post-card1-container">
          <span className="blog-post-card1-text">{props.label}</span>
          <span className="blog-post-card1-text1">{props.title}</span>
          <span className="blog-post-card1-text2">{props.description}</span>     
        </div>
      </a>
    </div>
  )
}

BlogPostCard1.defaultProps = {
  rootClassName: '',
  label: 'Essential Reading',
  title: 'ENISA Threat Landscape 2023',
  imageSrc:
    'https://images.unsplash.com/photo-1547841243-eacb14453cd9?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDIyfHxjaXR5fGVufDB8fHx8MTYyNjE4NjYxMg&ixlib=rb-1.2.1&w=1000',
  profileSrc:
    'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDd8fG1hbiUyMHBvcnRyYWl0fGVufDB8fHx8MTYyNjQzMTMwMw&ixlib=rb-1.2.1&h=1200',
  imageAlt: 'Cyber Security',
  description:
    'This is the eleventh edition of the ENISA Threat Landscape (ETL) report, an annual report on the status of the cybersecurity threat landscape. This year’s work has again been supported by ENISA’s ad hoc Working Group on Cybersecurity Threat Landscapes (CTL).',
}

BlogPostCard1.propTypes = {
  rootClassName: PropTypes.string,
  label: PropTypes.string,
  author: PropTypes.string,
  title: PropTypes.string,
  imageSrc: PropTypes.string,
  profileSrc: PropTypes.string,
  imageAlt: PropTypes.string,
  profileAlt: PropTypes.string,
  description: PropTypes.string,
  time: PropTypes.string,
}

export default BlogPostCard1
