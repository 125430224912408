import React, { useState } from 'react'

import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import FormSuccess from '../components/form-success'

import FeatureCard3 from '../components/feature-card3'
import BlogPostCard1 from '../components/blog-post-card1'
import BlogPostCard2 from '../components/blog-post-card2'
import BlogPostCard3 from '../components/blog-post-card3'
import Footer from '../components/footer'
import Header from '../components/header'
import './home.css'

const Home = (props) => {

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    company_name: "",
    employee_count: "",
    registration_page: "cyber-sicher-safe.de",
    email_address: "",
  });

  const [submit, setSubmit] = useState(false);
  
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(formData);
    registerUser(formData);
    setSubmit(!submit)
  };
  
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const registerUser = (data) => {
    return fetch('https://hc.nsurio.com/api/v1/register/', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': 'b7061546-75e8-444b-a2c4-f19655d07eb8',
            'Content-Type': 'application/json'
        }
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            console.log(response);
            return response;
           
          } else {
           console.log('Somthing happened wrong');
          }
    }).catch(err => err);
  }

  return (
    <div className="home-container">
      <Helmet>
        <title>Cyber Sicher - Safe</title>
        <meta property="og:title" content="Cyber Sicher - Safe" />
      </Helmet>

      <Header/>

      <div className="home-hero">      
            <div className="home-container01">
              <h1 className="home-text">Erhöhen Sie Ihre Cybersicherheit noch heute</h1>
              <span className="home-text01">
                <span>
                  43 % der gesamten Cyberkriminalität richtet sich gegen kleine Unternehmen und
                  rund die Hälfte aller weltweiten Cyberangriffe richtet sich Berichten zufolge gegen
                  Organisationen mit weniger als 250 Mitarbeitern.
                </span>
                <br></br>
                <br></br>
                <span>
                  Cyber Sicher &amp; Safe hat eine bahnbrechende Cyber-Risiko
                  Technologie entwickelt, die die Bewertung, das Verständnis und das Management von
                  Cyber-Risiken in Unternehmen einfacher und schneller als je zuvor macht.
                </span>
                <br></br>
                <br></br>
              </span>
              <div className="home-btn-group">
                <Link to="/register">
                  <button className="home-button button">Jetzt loslegen</button>
                </Link>
                <Link to="/register">
                  <button className="home-button1 button">Erfahren Sie mehr</button>
                </Link>
              </div>
        </div>
      </div>
      <div className="home-banner textarea">
        <h1 className="home-text08">Unsere Mission</h1>
        <span className="home-text09">
          <span>
            Bei Cyber Sicher &amp; Safe bieten wir Unternehmen eine erstklassige
            Cybersicherheit zu einem Bruchteil der Kosten und sorgen so für eine sichere Zukunft
            für alle. Wir definieren die Sicherheitsintelligenz neu, um den
            aufkommenden Bedrohungen einen Schritt voraus zu sein. Wir machen teure Cybersicherheit für
            jedermann zugänglich.
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <br></br>
          <br></br>
          <span>
            Helfen Sie uns, die digitale Welt für eine sichere Zukunft zu stärken.
          </span>
        </span>
        <Link to="/register">
          <button className="home-button2 button">Lesen Sie mehr</button>
        </Link>
      </div>
      <div className="home-features-container">
        <div className="home-features">
          <div className="home-features1">
            <h1 className="home-text14">
              <span>Die Cyber Sicher &amp; Safe Toolbox</span>
              <br></br>
            </h1>
            <span className="home-text17">
              <span>
                Kontinuierliches Aufdecken und Abschwächen der wichtigsten bekannten und
                unbekannten Risiken mit Bedrohungsanalysen, die auf Ihre Angriffsfläche
                zugeschnitten sind.
              </span>
              <br></br>
              <br></br>
              <span>
              Machen Sie sich die Macht der verwertbaren Informationen zunutze,
              um die Cybersicherheitsstrategie Ihres Unternehmens voranzutreiben.
              </span>
            </span>
            <div className="home-container02">
              <FeatureCard3
                title="Kontinuierliche Risikoüberwachung"
                action=" "
                description="Wir haben uns dem Schutz Ihrer Online-Geschäfte und -Aktivitäten verschrieben und bieten Echtzeitschutz vor Cyberkriminalität."
                rootClassName="rootClassName"
              ></FeatureCard3>
              <FeatureCard3
                title="Bildung &amp; Bewusstsein"
                action=" "
                description="Erhöhen Sie das Wissen über Cyber-Bedrohungen und das Bewusstsein dafür durch unsere spielerische Lernplattform, die darauf ausgelegt ist, das Wissen der Benutzer über Phishing zu vermitteln und zu erweitern."
                rootClassName="rootClassName3"
              ></FeatureCard3>
              <FeatureCard3
                title="Bedrohungen &amp; Fahrplan"
                action=" "
                description="Wissen, wie und wo man sich verbessert... Wir halten Sie über Bedrohungen auf dem Laufenden und bieten Ihnen sofortige, maßgeschneiderte Antworten."
                rootClassName="rootClassName5"
              ></FeatureCard3>
              <FeatureCard3
                title="24/7 Reaktion auf Vorfälle"
                action=" "
                description="Wir helfen Ihnen dabei, einen schwerwiegenden Cybervorfall schnell zu beheben, damit Sie sich wieder Ihrem Geschäft widmen können."
                rootClassName="rootClassName1"
              ></FeatureCard3>
            </div>
          </div>
        </div>
      </div>
      <div className="home-banner1">
        <div className="home-container03">
          <h1 className="home-text22">Buchen Sie eine demo</h1>
          <span className="home-text23">
            <span>
             Möchten Sie sehen, wie Cyber Sicher &amp; Safe Ihr Unternehmen schützen kann?
            </span>
            <br></br>
            <span>Melden Sie sich für einen Test an und wir melden uns bei Ihnen.</span>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </span>

          <div className="home-container04">
            {!submit ? (
              <form onSubmit={handleSubmit}>
                <input
                  type="text"
                  name="email_address"
                  placeholder="Email Addresse"
                  className="input home-quick-signup-text"
                  value={formData.email_address}
                  onChange={handleChange}
                  required={true}
                />
                <button className="home-quick-signup-button button">Senden</button>
              </form>
              ) : (
                <FormSuccess />
              )}
          </div>

        </div>
        <img
          alt="image"
          src="https://images.unsplash.com/photo-1460925895917-afdab827c52f?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDc0fHx0ZWNofGVufDB8fHx8MTcwMTk2NjE1N3ww&amp;ixlib=rb-4.0.3&amp;w=300"
          className="home-image"
        />
      </div>
      <div className="home-pricing"></div>
      <div className="home-banner2">
        <div className="home-banner3">
          <h1 className="home-banner-heading heading2">
          Ein Cyber Gesundheits Check mit Analyse und Empfehlungen
          </h1>
          <span className="home-banner-sub-heading">
            Entdecken Sie die Schwachstellen Ihres Unternehmens mit diesem einfachen,
            eigenständigen Cyber-Risiko-Bericht.
          </span>
          <Link to="/register">
            <button className="home-button2 button">
              Starten Sie Ihren Cyber Gesundheits Check
            </button>
          </Link>
        </div>
      </div>
      <div className="home-blog">
        <div className="home-container05">
          <BlogPostCard1
            author=" "
            rootClassName="rootClassName3"
          ></BlogPostCard1>
        </div>
        <div className="home-container06">
          <BlogPostCard2
            imageSrc="https://images.unsplash.com/photo-1465925508512-1e7052bb62e6?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDIzfHxjaXR5JTIwY2FifGVufDB8fHx8MTYyNjQ1MDMwNA&amp;ixlib=rb-1.2.1&amp;h=1200"
            rootClassName="rootClassName3"
          ></BlogPostCard2>
        </div>
        <div className="home-container07">
          <BlogPostCard3
            imageSrc="https://images.unsplash.com/photo-1464938050520-ef2270bb8ce8?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDE4fHxjaXR5fGVufDB8fHx8MTYyNjQ1MDI4MQ&amp;ixlib=rb-1.2.1&amp;h=1200"
            rootClassName="rootClassName1"
          ></BlogPostCard3>
        </div>
      </div>

      <Footer/>


    </div>
  )
}

export default Home
