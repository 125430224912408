import React, { useRef, useState } from 'react'

import { Helmet } from 'react-helmet'
import ReCAPTCHA from "react-google-recaptcha";

import './register.css'
import FormSuccess from '../components/form-success'
import Footer from '../components/footer'
import Header from '../components/header'

const Register = (props) => {

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    company_name: "",
    employee_count: "",
    registration_page: "cyber-sicher-safe.de",
    email_address: "",
    company_address: "",
    inbound_url: window.location.href,
  });
  const recaptcha = useRef();

  const [submit, setSubmit] = useState(false);
  
  const handleSubmit = (event) => {
    event.preventDefault();
    const captchaValue = recaptcha.current.getValue();
    if (!captchaValue) {
      alert("Bitte überprüfen Sie das reCAPTCHA");
      return false;
    }
    registerUser(formData);
    setSubmit(!submit)
    gtag_report_conversion();
    window.lintrk('track', { conversion_id: 15275066 });
  };
  
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const registerUser = (data) => {
    return fetch('https://hc.nsurio.com/api/v1/register/', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'x-api-key': 'b7061546-75e8-444b-a2c4-f19655d07eb8',
            'Content-Type': 'application/json'
        }
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            console.log(response);
            
            return response;       
          } else {
           console.log('registration error');
          }
    }).catch(err => err);
  }

  return (
    <div className="register-container">
      <Helmet>
        <title>Register - Cyber Sicher & Safe</title>
        <meta property="og:title" content="Register - Cyber Sicher & Safe" />
      </Helmet>

      <Header/>

      <div className="register-banner">
        <span className="register-text">
          <span className="register-text01">CYBERSICHERHEIT GESUNDHEITS CHECK FÜR UNTERNEHMEN</span>
        </span>
        <h1 className="register-text02">Erhöhen Sie Ihre Cybersicherheit noch heute</h1>
        <span className="register-text03">
          <span>
            <span>
            Melden Sie sich für Ihren Cyber Gesundheits Check an und erhalten Sie Informationen über Ihre Cyber Bedrohungslage.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </span>
          <span>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </span>
        </span>
         
        <div className="register-container1">
        {!submit ? (
          <form onSubmit={handleSubmit}>
            <input 
              type="hidden" 
              name="registration_page"
            />
          <label className="register-label">Vorname</label>
          <input
            type="text"
            name="first_name"
            label="First Name"
            placeholder=""
            className="register-textinput input"
            value={formData.first_name}
            onChange={handleChange}
            required={true}
          />
          <label className="register-label">Nachname</label>
          <input
            type="text"
            name="last_name"
            label="Last Name"
            placeholder=""
            className="register-textinput input"
            value={formData.last_name}
            onChange={handleChange}
            required={true}
          />
          <label className="register-label">Geschäfts-E-Mail-Adresse</label>
          <input
            type="text"
            name="email_address"
            label="Email Address"
            placeholder=""
            className="register-textinput input"
            value={formData.email_address}
            onChange={handleChange}
            required={true}
          />
          <label className="register-label">Name des Unternehmens</label>
          <input
            type="text"
            name="company_name"
            placeholder=""
            className="register-textinput input"
            value={formData.company_name}
            onChange={handleChange}
            required={true}
          />
          <label className="register-label">Website des Unternehmens</label>
          <input
            type="text"
            name="company_address"
            className="register-textinput input"
            value={formData.company_address}
            onChange={handleChange}
            required={true}
          />
          <div className="register-disclaimer">
          Bitte beachten Sie, dass dieser Cyber Health Check nur für Unternehmen geeignet ist. Um Ihren Cyber-Sicherheitsstatus zu überprüfen, benötigen wir eine gültige E-Mail-Adresse sowie die aktuelle Website Ihres Unternehmens.
          Andernfalls kann Ihre Anfrage nicht bearbeitet werden.
          </div>
          <label className="register-label">Anzahl der Mitarbeiter</label>
          <select
            type="text"
            name="employee_count"
            placeholder="Anzahl der Mitarbeiter"
            className="register-select input"
            value={formData.employee_count}
            onChange={handleChange}
            required={true}
          >
            <option>Optionen auswählen</option>
            <option value="1-0">1-10</option>
            <option value="10-50">10-50</option>
            <option value="50-200">5-200</option>
            <option value="200">Mehr als 200</option>
          </select>
          <ReCAPTCHA 
            ref={recaptcha}
            className="captcha"
            sitekey="6LfZszUpAAAAAMZ3ue1PlMedQgBrtoLJzq0kisWK" 
          />
          <button className="register-button button">Abonnieren</button>
          </form>
          ) : (
            <FormSuccess />
          )}
        </div>
      </div>

      <Footer/>
    </div>
  )
}

export default Register
