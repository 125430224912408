import React from 'react'

import PropTypes from 'prop-types'

import './form-success.css'

const FormSuccess = (props) => {
  return (
    <div className="form-success-feature-card">
        <div className="form-success-text">{props.SubHeading}</div>
    </div>
  )
}

FormSuccess.defaultProps = {
  SubHeading:
    'Danke!',
}

FormSuccess.propTypes = {
  SubHeading: PropTypes.string,
}

export default FormSuccess
